
import { defineComponent, reactive, onMounted, computed } from "vue";
import { ScrollTopComponent } from "@/assets/ts/components/_ScrollTopComponent";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import useBillStatus from "@/core/services/compositions/enum/useBillStatus";
import useBillType from "@/core/services/compositions/enum/useBillType";
import useInstitution from "@/core/services/compositions/enum/useInstitution";
import useChannel from "@/core/services/compositions/enum/useChannelByHash";

interface Filter {
  account_number: string;
  account_holder: string;
  bcn: string;
  bank_code: string;
  type: string;
  institution: string;
  channel: string;
}

export default defineComponent({
  name: "filter-bill",
  components: {},
  setup(props, context) {
    const { billStatus } = useBillStatus();
    const { billType } = useBillType();
    const { query } = useQuery();

    const { channel } = useChannel();

    const queryParam = reactive({
      search: ''
    });

    const fetchParams = computed(() => ({
      name: queryParam.search,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { institution, isValidating: isValidatingInstitution } = useInstitution(() => fetchParams.value);

    const data = reactive<Filter>({
      account_number: query.value.account_number || '',
      account_holder: query.value.account_holder || '',
      bank_code: query.value.bank_code || '',
      bcn: query.value.bcn || '',
      type: query.value.type || '',
      institution: query.value.institution || '',
      channel: query.value.channel || '',
    });

    const filter = () => {
      if (data.type != 'channel') {
        data.channel = '';
      }

      if (data.type != 'institution') {
        data.institution = '';
      }

      context.emit('filtered', data);
    };

    const reset = () => {
      data.account_number = '';
      data.account_holder = '';
      data.bank_code = '';
      data.bcn = '';
      data.type = '';
      data.institution = '';
      data.channel = '';
    };

    const searchInstitution = (val) => {
      queryParam.search = val;
    }

    onMounted(() => {
      ScrollTopComponent.reinitialization();
    });

    return {
      data,
      filter,
      reset,
      billStatus,
      billType,
      screen,
      institution,
      isValidatingInstitution,
      searchInstitution,
      channel
    };
  }
});
