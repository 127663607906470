import useRequest, { CommonResponse } from "./../useRequest";
import { computed } from "vue";

const useBillStatus = () => {
  const { data, ...other } = useRequest<CommonResponse<any>>(
    `/app/enum/bill-status`
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data.data;
  });

  return {
    billStatus: enhancedData,
    ...other
  };
};

export default useBillStatus;
