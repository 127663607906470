
import { reactive, defineComponent, computed, onMounted, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import {
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import FilterAccount from "./filter-account.vue";
import useAccountList from "@/core/services/compositions/account/useAccountList";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/skeleton/Table.vue";
import { MenuComponent } from "@/assets/ts/components";
import Pagination from "@/components/Pagination.vue";
import moment from 'moment'
import { ElNotification } from 'element-plus'
import { checkPermission } from '@/router/authentication';

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterAccount
  },
  setup() {
    // init
    const { t } = useI18n();
    const router = useRouter();

    // breadcrumb
    setNewPageBreadcrumbs(t("management.account.title"), [
      { title: t("management.account.title") }
    ]);

    const gotoCreate = () => {
      router.push({ name: "app.management.account.create" });
    };

    const gotoInquiry = () => {
      router.push({ name: "app.finance.account.inquiry" });
    };

    const { query } = useQuery();
    const fetchParams = computed(() => ({
      account_number: query.value.account_number || '',
      account_holder: query.value.account_holder || '',
      bank_code: query.value.bank_code || '',
      bcn: query.value.bcn || '',
      type: query.value.type || '',
      institution: query.value.institution || '',
      channel: query.value.channel || '',
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));
    const { data, isValidating, error } = useAccountList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage
      });
    };

    const form = reactive({
      account_number: '',
      account_holder: '',
      bank_code: '',
      bcn: '',
      type: '',
      institution: '',
      channel: '',
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    });

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);
      mutateQuery({
        page: 1,
        account_number: form.account_number,
        account_holder: form.account_holder,
        bank_code: form.bank_code,
        bcn: form.bcn,
        type: form.type,
        institution: form.institution,
        channel: form.channel,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      gotoCreate,
      data,
      isValidating,
      filtered,
      handleChangePage,
      gotoInquiry,
      moment,
      checkPermission
    };
  },
  methods: {}
});
